.overlock_Font {
  font-family: overLock;
  user-select: none;
  font-size: 18px;
}

.title {
  border-bottom: 1px solid #ffffff73;
  width: 650px;
  margin: 30px 0;
  color: rgb(146,206,255);
  font-family: celticGaramond;
}

.requirements_title {
  color: white;
}

.button_text_group {
  display: flex;
  align-items: center;
  width: 650px;
  gap: 100px;
  margin: 15px 0;
  padding: 0 10px;
  /* border: 2px solid rgba(128, 128, 128, 0.2); */
  border-radius: 12px;
}

.redirect_button {
  width: 170px;
  height: fit-content;
}

li {
  list-style-type: disc;
}

.content_column {
  margin: 0 40px;
  display: flex;
  flex-direction: column;
  align-items: center;

  width: fit-content;
}

.white_text {
  color: white;
  font-size: 18px;
}

.bottom_line {
  color: #009fff;
  font-size: 18px;
  font-style: oblique;
}

.requirements {
  width: 650px;
  margin: 15px 0;
}

span {
  color: #009fff;
  font-weight: bold;
}

.image_link_group {
  margin-top: 15px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  cursor: pointer;
}

.image_link img {
  width: 75%;
}

.disclaimer {
  width: 650px;
  color: white;
}
