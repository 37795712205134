.sidebar_box {
  margin-top: 30px;
  width: 250px;
  font-size: 2rem;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.sidebar_box_title {
  display: flex;
  align-items: center;
  font-size: 32px;
  color: white;
  font-family: overLock;
}

.sidebar_box_info_div {
  color: white;
  width: 225px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 2px;
  padding: 10px;
  background-color: #1b283d;
  margin-bottom: 25px;
  display: flex;
}

.clock_image_wrapper {
  position: relative;
  font-family: overLock;
}

.time_image {
  width: 3.8vw;
  position: absolute;
  top: 15px;
  left: -60px;
  z-index: 1;
}

#events_box {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.events_entry {
  display: flex;
  justify-content: left;
}

#events_list {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.imageContainer {
  position: relative;
  cursor: pointer;
}

.smallImage {
  width: 280px; 
  height: auto;
  /* transition: transform 0.3s; */
}

/* .expandedImage {
  transform: scale(1.8); 
  transition: transform 0.3s;
} */

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7); 
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.fullscreenImage {
  width: 80%;
  height: auto;
  object-fit: contain;
}

.calendar_button {
  margin-top: 10px;
}

.calendar_form {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

#calendar_input {
  width: 280px;
}

.faq_box {
  background-image: linear-gradient(to bottom left, rgb(94,91,205) , rgb(227,88,58));
  width: 296px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.faq_box:hover {
  background-image: linear-gradient(to bottom left,rgb(227,88,58), rgb(94,91,205) );
}

.image_box {
  filter: brightness(.85);
  margin-bottom: 15px;
}

.image_box:hover {
  filter: brightness(1);
}

#moreInfo {
  color: white;
  font-size: 16px;
  text-align: center;
  font-family: overLock;
}
