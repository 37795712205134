.paginationItemStyle {
	&.page-item {
		button {
			background-color: #162235;
			border-color: #04060a;
			color: #ffffff;
		}
		&.active, &:hover {
			button {
				background-color: #233552;
				color: rgb(34,138,252);
			}
		}
	}
}