.form-control {
  margin-bottom: 0.5rem;
  margin-top: 1rem;
}

.form-control input,
.form-control label {
  display: block;
}

.form-control label {
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.form-control input,
.form-control select {
  width: 100%;
  font: inherit;
  padding: 5px 20px;
  border-radius: 4px;
  border: 1px solid #ccc;
}

.form-control input:focus {
  outline: none;
  border-color: #240370;
  background-color: #e0d4fd;
}

.control-group {
  display: flex;
  flex-direction: column;
  column-gap: 1rem;
  flex-wrap: wrap;
}

.control-group .form-control {
  min-width: 15rem;
  flex: 1;
}

button {
  font: inherit;
  background-color: #240370;
  color: white;
  border: 1px solid #240370;
  padding: 0.5rem 1.5rem;
  border-radius: 4px;
  cursor: pointer;
}

button:hover,
button:active {
  background-color: #33059e;
  border-color: #33059e;
}

button:disabled,
button:disabled:hover,
button:disabled:active {
  background-color: #ccc;
  color: #292929;
  border-color: #ccc;
  cursor: not-allowed;
}

.form-actions {
  text-align: right;
}

.form-actions button {
  margin-left: 1rem;
}

.invalid input {
  border: 1px solid #b40e0e;
  background-color: #fddddd;
}

.invalid input:focus {
  border-color: #ff8800;
  background-color: #fbe8d2;
}

.error-text {
  color: #b40e0e;
  text-align: center;
}

.register-box {
  /*border: 2px solid rgb(248, 249, 250);
  border-radius: 12px;*/
  /* box-shadow: 10px 10px 5px rgb(248, 249, 250); */
  display: flex;
  background-color: #141e43a1;
  flex-direction: column;
  width: 35vw;
  padding: 2vw;
  margin-bottom: 600px;
}

.register-box2 {
  /*border: 2px solid rgb(248, 249, 250);
  border-radius: 12px;*/
  /* box-shadow: 10px 10px 5px rgb(248, 249, 250); */
  display: flex;
  background-color: transparent;
  flex-direction: column;
  width: 700px;
  padding: 2vw;
}

.centered {
  display: flex;
  justify-content: center;
  margin-top: 20vh;
}

.register-title {
  color: white;
  font-family: overLock;
  font-size: 2rem;
  text-align: center;
}

.register-title2 {
  color: rgb(197, 40, 100);
  font-family: overLock;
  font-size: 2rem;
  text-shadow: 2px 2px 2px black;
  text-align: center;
}

.login_title {
  text-align: center;
  padding-top: 15px;
  margin-bottom: 0;
}

.login_button {
  margin-left: 85px;
}

.register-title3 {
  color: white;
  font-family: overLock;
  font-size: 2rem;
  text-shadow: 2px 2px 2px black;
  text-align: center;
}

.register-title4 {
  color: rgb(79, 190, 94);
  font-family: overLock;
  font-size: 28px;
  text-shadow: 2px 2px 2px black;
  text-align: center;
}

.register-title5 {
  color: rgb(197, 40, 100);
  font-family: overLock;
  font-size: 28px;
  text-shadow: 2px 2px 2px black;
  text-align: center;
  user-select: none;
}

.overlock_Font2 {
  font-family: overLock;
  color: rgb(197, 40, 100);
  text-shadow: 1px 1px 1px rgb(0, 0, 0);
  user-select: none;
  font-size: 20px;
}