.navbar {
    background-color: rgb(8,22,36);
    display: flex;
    align-items: center;
    max-height: 65px;
}

#navbar_left {
    position: relative;
    top: 30px;
    padding-left: 100px;
    z-index: 999;
}

.navbar_right {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 50px;
}

#navbar_logo {
    width: 250px;
}

#navbar_links {
    display: flex;
    justify-content: space-between;
    gap: 1.8rem;
    padding-top: 10px;
    font-family: celticGaramond;
    margin-right: auto;
    margin-left: auto;
}

.nav_link {
    color: white;
    text-decoration: none;
    font-size: larger;
}

.active {
    color: rgb(146,206,255);
    font-weight: bold;
}

ul {
    list-style-type: none;
    margin: 0;
}

.input_form {
    padding: 5px 20px;
    display: flex;
    justify-content: center;
}

.username {
    margin: 5px;
}

#login_button {
    min-width: 78px;
}

@media screen and (max-width: 768px) {
    #navbar_links{
     flex-direction: column;
    }

}
