#home-bg-image {
  background-size: cover;
  background-attachment: fixed;
  width: 100vw;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.pagination {
  display: flex;
  justify-content: center;
}

#backgroundImage {
  background-image: url("./images/background.png");
  background-size: cover;
  background-attachment: fixed;
}