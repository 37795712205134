#input_box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  margin: 15px 0;
}

input {
  width: 500px;
}

#input_form {
  
  border: 2px solid black;
  border-radius: 12px;
  padding: 15px;
}

.input_label {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  color: white;
  padding: 10px 0;
}

#add_button {
  display: inline;
  background-color: transparent;
  border: 2px solid red;
  border-radius: 12px;
}

#add_button:hover {
  box-shadow: 1px 1px 5px grey;
}
