.page_img {
    width: 80%;
    height: auto;
  }
  
  
  .error_page {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }