#container_box {
  position: relative;
  margin: auto;
  margin-top: 80px;
  width: 1080px;
  min-width: 800px;
  padding-bottom: 75px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgba(8,22,36, 0.95);
  
}

#container_content {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: space-between;
}

.pagination {
  display: flex;
  justify-content: center;
}

.page-item {
  display: none;
}

.content_column {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: fit-content;
  /*margin: 0 30px;*/
}

.sidebar {
  background-color: transparent;
  width: 300px;
  /*border-radius: 12px;*/
}

.carousel_image {
  width: 700px;
  height: 135px;
}

.carousel {
  position: relative;
  margin: 30px 0px 0px 0px;
}