.table {
  background-color: rgb(22,34,53);
  margin-bottom: 20px;
  margin-top: 30px;
}

.th {
  padding: 5px;
  min-width: 150px;
  text-align: center;
  border: 1px solid black;
  color: #92ceff;
  background: #212f47;
}

.td {
  padding: 5px;
  min-width: 150px;
  text-align: center;
  border: 1px solid black;
    color: white;
}

.title {
  border-bottom: 1px solid #ffffff73;
  width: 650px;
  margin: 30px 0px 20px 0px;
  color: rgb(146,206,255);
  font-family: celticGaramond;
}

.content_column{
  margin: 0px 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: fit-content;
}

.overlock_Font2 {
  font-family: overLock;
  user-select: none;
  font-size: 18px;
  color: white;
  text-align: left;
}

.box {
  width: 650px;
  margin: 15px 0;
}