.newscard_wrapper {
  margin: 0px 40px 30px 40px;
  
}

.newscard_card {
  width: 700px;
  background-color: rgb(22,34,53);
  color: rgb(25,38,49);
  padding: 20px;
  /*border-radius: 10px;*/
}

.newscard_title {
  font-size: 1.8rem;
  color: rgb(34,138,252);

}

.newscard_image {
  max-width: 100%;
  max-height: 250px; 
  object-fit: contain;
  
  border-radius: 10px;
}

.newscard_image_wide {
  max-width: 100%;
}

.newscard_text {
  text-align: justify;

  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.card_deleteIcon {
  display: flex;
  justify-content: space-between;
  
}

.deleteIcon {
  /* font-size: medium; */
  color: white;
}

.deleteIcon:hover{
  color: red;
  cursor: pointer;
}

.text {
  color: white;
  padding-top: 15px;
  font-family: overLock;
  font-size: 18px;
}

.date {
  color: white;
  padding-bottom: 15px;
  font-size: 0.8rem;
  font-family: overLock;
}