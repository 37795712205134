#welcome_box {
  position: relative;
  margin: auto;
  margin: 30px 0px 20px 0px;
  width: 700px;
  text-align: center;
}

#welcome_title {
  color: rgb(146,206,255);  /* rgb(94, 173, 237) <-- a little darker blue / looks nice also */
  padding: 1px;
  font-family: celticGaramond;
  /* font-size: must be 30px / is 32px */
}

#welcome_text {
  color: white;
  padding: 5px 0 0 0;
  font-size: 16px; /* must be 18px */
  font-family: overLock;
  font-size: 18px;
}
