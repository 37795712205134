.content_column {
  margin: 0 40px;
  display: flex;
  flex-direction: column;
  align-items: center;

  width: fit-content;
}

.title {
  border-bottom: 1px solid #ffffff73;
  width: 650px;
  margin: 30px 0;
  color: rgb(146, 206, 255);
  font-family: celticGaramond;
}

.disclaimer {
  width: 650px;
  color: white;
}

.overlock_Font {
  font-family: overLock;
  user-select: none;
  font-size: 18px;
}

.overlock_Font2 {
  font-family: overLock;
  user-select: none;
  font-size: 20px;
  color: aqua;
}

.bottom_line {
  color: #009fff;
  font-size: 18px;
}

.paymentOptions {
    width: 700px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

.coinDiv {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 25px;
}

.coinDiv button {
  margin-bottom: 10px;
}

.coinImage {
  width: 162px;
  height: 140px;
}

.coinSum {
  color: white;
  text-shadow: rgba(255, 255, 255, 0.9) 0px 0px 14px;
  margin: 10px 0 10px 0;
}

.payButton {
  color: white;
  font-size: 16px;
  border-radius: 50px;
  width: 280px;
  font-weight: bold;
  transition: 0.2s;
}

.stripeButton {
  background-color: rgb(87, 134, 245);
}

.paypalButton {
  background-color: rgb(0, 87, 225);
}

.payButton:hover {
    background-color: transparent;
}

.stripeButton:hover {
    border-color: rgb(87, 134, 245);
    color: rgb(87, 134, 245);
    text-shadow: rgba(87, 134, 245, 0.9) 0px 0px 14px;
}

.paypalButton:hover {
    border-color: rgb(0, 87, 225);
    color: rgb(0, 87, 225);
    text-shadow: rgba(0, 87, 225, 0.9) 0px 0px 14px;
}
